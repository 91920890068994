import styled from "styled-components";
import { Icon } from "../../../components";
import { InputThemeType, PlaceholderThemeType } from "../../../types";

interface StyleProps {
  $styles: {
    input: InputThemeType;
    placeholder: PlaceholderThemeType;
  };
  disabled?: boolean;
  $classes?: string;
  $failedRequired?: boolean;
  $missed?: boolean;
  $hasValidation?: boolean;
  $clearable?: boolean;
  $hasEndAdorner?: boolean;
  $hasStartAdorner?: boolean;
}

export const InputGroup = styled.div.attrs<StyleProps>(({ $classes }) => ({
  className: "bcr-input-group input-group " + $classes,
}))<StyleProps>`
  .input-group-text {
    height: ${(props) => props.$styles.input!.height};
    background: ${(props) =>
      props.disabled ? props.$styles.input!.disabled!.background : props.$styles.input!.adorner?.background};
    border-color: ${(props) => props.theme.input["border-color"]};
  }

  &.is-invalid .input-group-text {
    border-color: ${(props) =>
      props.$missed ? props.$styles.input.missed!["border-color"] : props.$styles.input.required!["border-color"]};
  }
`;

export const InputStyled = styled.input.attrs(() => ({
  autoComplete: "off",
}))<StyleProps>`
  &&& {
    background: ${(props) =>
      props.disabled ? props.$styles.input!.disabled!.background : props.$styles.input!.background};
    height: ${(props) => props.$styles.input!.height};

    ::placeholder {
      color: ${(props) => props.$styles.placeholder["color"]} !important;
      font-weight: ${(props) => props.$styles.placeholder["font-weight"]};
      opacity: 1;
      font-style: ${(props) => props.$styles.placeholder["font-style"]};
    }
    :focus {
      background: ${(props) => props.$styles.input!.focused?.background || props.$styles.input!.background};
      border: 1px solid ${(props) => props.$styles.input["border-color"]};
      outline: none;
      box-shadow: none;
    }
    ${(props) => (props.disabled ? `color: ${props.theme["grey"]};` : "")};
    border: 1px solid ${(props) => props.$styles.input["border-color"]};
    font-size: ${(props) => props.$styles.input["size"]};
    color: ${(props) => props.$styles.input["color"]};

    &.form-control.is-invalid {
      border: 1px solid
      ${(props) =>
        props.$missed
          ? props.$styles.input.missed!["border-color"]
          : props.$styles.input.required!["border-color"]} !important;
      color: ${(props) =>
        props.$missed ? props.$styles.input.missed!["color"] : props.$styles.input.required!["color"]};
      background-image: none;
    }

    &&& {
      ${(props) => (props.$hasEndAdorner ? "border-right: none !important;" : "")}
      ${(props) => (props.$hasStartAdorner ? "border-left: none !important;" : "")}
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    ${({ type }) => {
      switch (type) {
        case "number":
          return `
      -moz-appearance: textfield;
    `;
        default:
          return "";
      }
    }}
`;

export const InputAdorner = styled.span.attrs(() => ({
  className: "input-group-text",
}))<StyleProps>`
  background: ${(props) => props.$styles.input?.adorner?.background};
  padding: 0 0.35rem 0 0;
  display: flex;
  align-items: center;
  justify-content: end;
  width: 1.625rem;
`;

export const InputCounterContainer = styled(InputAdorner)`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 0;
  line-height: 0 !important;
`;

export const InputCounterIcon = styled(Icon)`
  line-height: 0 !important;
`;

export const LabelContainer = styled.div<StyleProps>`
  display: ${(props) => (props.$hasValidation ? "flex" : "normal")};
  ${(props) => (props.$hasValidation ? `flex-direction: row;` : "")};
`;
