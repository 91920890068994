import clsx from "clsx";
import React, { MouseEvent } from "react";
import { useTheme } from "../../../../hooks";
import { DatatableThemeType, SortDirection } from "../../../../types";
import { HeaderAddRowText, HeaderCellDiv, HeaderText } from "../DatatableStyles";
import SortIcon from "./SortIcon";

type HeaderCellProps = {
  width?: number;
  headerHeight?: number;
  columnId?: string;
  sortable?: boolean;
  onClick?: () => void;
  backgroundColor?: string;
  sortDirection?: SortDirection;
  sorted?: boolean;
  cellClass?: string;
  name?: React.ReactNode;
  styles?: DatatableThemeType;
  addRow?: boolean;
  addRowFunction?: () => void;
  isFixed?: boolean;
  fixedStart?: number;
};

const HeaderCell: React.FC<React.PropsWithChildren<HeaderCellProps>> = ({
  width = 50,
  headerHeight = 35,
  columnId = "",
  sortable = false,
  onClick = () => {},
  backgroundColor = "",
  sortDirection,
  sorted = false,
  cellClass = "",
  name = "",
  styles = {},
  addRow = false,
  addRowFunction = () => {},
  isFixed = false,
  fixedStart = 0,
}) => {
  const { Theme } = useTheme();
  const StylesOverride: DatatableThemeType = { ...Theme.datatable, ...styles };
  return (
    <HeaderCellDiv
      className={clsx(`d-flex align-items-end bcr-table-header-cell`, cellClass)}
      id={columnId}
      $elemWidth={width}
      $elemHeight={headerHeight}
      key={columnId}
      $sortable={sortable}
      $background={backgroundColor}
      $styles={StylesOverride}
      $isFixed={isFixed}
      $fixedStart={fixedStart}
    >
      <HeaderText className={cellClass} $addRow={addRow} onClick={onClick}>
        <>
          <div>
            {name}
            {sorted && <SortIcon direction={sortDirection} styles={StylesOverride} />}
          </div>
          {addRow && (
            <HeaderAddRowText
              $styles={StylesOverride}
              onClick={(event: MouseEvent<HTMLDivElement>) => {
                event.stopPropagation();
                event.preventDefault();
                addRowFunction();
              }}
            >
              add
            </HeaderAddRowText>
          )}
        </>
      </HeaderText>
    </HeaderCellDiv>
  );
};

export default HeaderCell;
