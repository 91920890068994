import React from "react";
import Checkbox from "../../../../form/inputs/checkbox/Checkbox";
import {
  ASC,
  DataTableColumn,
  DataTableDataType,
  DatatableThemeType,
  DESC,
  NO_DIRECTION,
  SortDirection,
} from "../../../../types";
import { useDataTable } from "../DataTableContext";
import {
  ActionsHeader,
  ExpansionButtonHolder,
  HeaderCheckboxHolder,
  HeaderDiv,
  SubRowButtonHolder,
} from "../DatatableStyles";
import HeaderCell from "./HeaderCell";
import SortIcon from "./SortIcon";
import SubrowButton from "./SubrowButton";

export type HeaderProps<T extends DataTableDataType> = {
  columns: DataTableColumn<T>[];
  headerHeight?: number;
  sortColumn?: string;
  sortDirection?: SortDirection;
  sortFunction?: (key: keyof T, direction: SortDirection) => void;
  expansion?: boolean;
  subrows?: boolean;
  expandAll?: (expand: boolean) => void;
  anyAreExpanded?: boolean;
  columnSizes?: number[];
  minWidth?: number;
  displayExpansionButton?: boolean;
  expandable?: boolean;
  showCheckbox?: boolean;
  selectAllRows?: (value: boolean) => void;
  allRowsSelected?: boolean;
  selectAll?: boolean;
  styles?: DatatableThemeType;
  addRow?: boolean;
  addRowFunction?: () => void;
};

const Header = <T extends DataTableDataType>() => {
  const {
    columns,
    heights: { headerHeight },
    widths: { tableWidth },
    sort: { column: sortColumn, direction: sortDirection, sortFunction },
    subRows: { hasSubRows = false },
    expansion: {
      expandAll = () => {},
      expansionElement,
      anyAreExpanded = false,
      displayExpandAllButton = false,
      expandable = true,
    },
    selection: { showCheckbox = false, selectAllRows = () => {}, allRowsSelected = false, showSelectAll = true },
    addRows: { shouldAddRows = false, addRowFunction = () => {} },
    columnSizes = [],
    actions,
    styles,
    type,
  } = useDataTable<T>();

  const handleSort = (canSort: boolean, key: string) => {
    if (canSort) {
      let direction;
      if (sortColumn !== key) {
        direction = ASC;
      } else if (sortDirection === ASC) {
        direction = DESC;
      } else {
        direction = NO_DIRECTION;
      }
      const newKey = direction === NO_DIRECTION ? "" : key;
      sortFunction(newKey as keyof T, direction);
    }
  };

  return (
    <HeaderDiv height={headerHeight + 2} $minWidth={tableWidth} $styles={styles}>
      {showCheckbox && (
        <HeaderCheckboxHolder>
          {showSelectAll && (
            <Checkbox
              id="select-all-checkbox"
              checked={allRowsSelected}
              onChange={() => {
                selectAllRows(!allRowsSelected);
              }}
            />
          )}
        </HeaderCheckboxHolder>
      )}
      {!!expansionElement && (
        <ExpansionButtonHolder>
          {!!displayExpandAllButton && <SubrowButton expanded={anyAreExpanded} onClick={expandAll} loading={false} />}
        </ExpansionButtonHolder>
      )}
      {hasSubRows && expandable && (
        <SubRowButtonHolder>
          {!!displayExpandAllButton && <SubrowButton expanded={anyAreExpanded} onClick={expandAll} loading={false} />}
        </SubRowButtonHolder>
      )}
      {!!actions?.actions.length && <ActionsHeader />}
      {columns.map((col: DataTableColumn<T>, index: number) => {
        const { name, key, sortable = false, cellClass = "", width } = col;
        const columnId = `${Math.floor(Math.random() * 10000)}-${String(key)}`;
        const headerWidth = !!columnSizes[index] ? columnSizes[index] : width;
        // Only pass down addRowProps for the last column
        const addRowProps = index === columns.length - 1 ? { addRow: shouldAddRows, addRowFunction } : {};
        // for now fixed columns are not supported in virtualized tables
        const isFixed = col.fixed && type === "not-virtualized";
        let fixedStart;
        if (isFixed) {
          fixedStart = columnSizes.slice(0, index).reduce((acc, curr) => acc + curr, 0);
        }
        return (
          <HeaderCell
            columnId={columnId}
            key={columnId}
            width={headerWidth}
            headerHeight={headerHeight}
            sortable={sortable}
            onClick={() => handleSort(sortable, String(key))}
            sorted={sortColumn === key}
            backgroundColor={col.headerBackground}
            name={name}
            sortDirection={sortDirection}
            cellClass={cellClass}
            isFixed={isFixed}
            fixedStart={fixedStart}
            {...addRowProps}
          />
        );
      })}
    </HeaderDiv>
  );
};

export { ExpansionButtonHolder, HeaderCell, SortIcon, SubrowButton };

export default Header;
