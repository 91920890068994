import clsx from "clsx";
import React, { PropsWithChildren, useCallback } from "react";
import Checkbox from "../../../../form/inputs/checkbox/Checkbox";
import { DataTableDataType, ExpansionType, NO_DIRECTION } from "../../../../types";
import { areObjectsEqual, deepClone, typedMemo } from "../../../../utils";
import { useDataTable } from "../DataTableContext";
import { BodyCell, RowCheckboxHolder, RowDiv, RowExpansionHolder, SubRowButtonHolder } from "../DatatableStyles";
import DataItem from "./DataItem";
import ExpandButton from "./ExpandButton";
import MoreActionsButton from "./MoreActionsButton";
import SubrowButton from "./SubrowButton";

function rowPropsCompare<T extends DataTableDataType>(
  prevProps: Readonly<PropsWithChildren<RowProps<T>>>,
  nextProps: Readonly<PropsWithChildren<RowProps<T>>>,
): boolean {
  return (
    areObjectsEqual(prevProps.value, nextProps.value) &&
    prevProps.loading === nextProps.loading &&
    areObjectsEqual(prevProps.style, nextProps.style)
  );
}

type RowProps<T extends DataTableDataType> = {
  id?: string;
  index: number;
  value: T;
  recompute?: (value: number) => void;
  loading?: boolean;
  style: React.CSSProperties;
};

const Row = <T extends DataTableDataType>(props: RowProps<T>) => {
  const { id, index, value, recompute = () => {}, loading = false, style } = props;
  const {
    tableId,
    heights: { rowHeight },
    rowPrefix = "",
    columns,
    sort: { column: sortColumn, direction: sortDirection },
    columnSizes = [],
    expansion: {
      expansionElement,
      expandable,
      displayExpansionButton,
      tableExpanded,
      expandCallback,
      expandedRows,
      expandedKey,
    },
    subRows: { subRowStylesGetter = () => {} },
    selection: { isRowSelected, onSelectionChange, isSelectionDisabled, showCheckbox = false },
    rowDisabledKey,
    rowClick,
    actions,
    rowStylesGetter,
    styles,
  } = useDataTable<T>();
  const hasSubRows = !!value?.subRows;
  const expandedValue: boolean | undefined = !!expandedRows[index]
    ? expandedRows[index].expanded
    : !!value && !!value[expandedKey]
    ? !!value[expandedKey]
    : undefined;
  const expanded = deepClone(expandedValue);
  const rowStyle = rowStylesGetter(index, value) || {};

  if (!!value) {
    if (value[rowDisabledKey] === undefined) {
      // @ts-ignore
      value[rowDisabledKey] = false;
    }
  }

  const StylesOverride = {
    ...styles,
    row: {
      ...styles.row,
      ...rowStyle,
    },
  };

  const isRowDisabled: boolean = !!value && !!value[rowDisabledKey as keyof typeof value];

  const ExpansionElement: React.FC<React.PropsWithChildren<ExpansionType<T>>> | undefined = expansionElement;

  const expand = useCallback((): void => {
    expandCallback(index, value, !expanded);
    tableExpanded(index, !expanded, value);
    recompute(index);
  }, [index, recompute, tableExpanded, expanded, value]);

  const changeSelection = useCallback(() => {
    onSelectionChange([index], !isRowSelected(index));
  }, [onSelectionChange, index, isRowSelected]);

  const shouldShowSubRows: boolean = (expanded && hasSubRows) || !expandable;

  return (
    <div style={style} id={id}>
      <RowDiv
        id={`${tableId}-row-${index}`}
        height={rowHeight}
        $styles={StylesOverride}
        $expanded={expanded}
        $selected={isRowSelected(index)}
        disabled={!loading && isRowDisabled}
        $isClickable={rowClick ? !rowClick.isClickable || rowClick.isClickable(index, value) : undefined}
        onClick={() => {
          if (!isRowDisabled && !!rowClick) {
            if (!rowClick.isClickable || rowClick.isClickable(index, value)) {
              rowClick.onClick(index, value);
            }
          }
        }}
      >
        {showCheckbox ? (
          <RowCheckboxHolder>
            {!loading && (
              <Checkbox
                id={`data-table-row-select-${index}`}
                checked={isRowSelected(index)}
                onChange={changeSelection}
                disabled={isRowDisabled || isSelectionDisabled(index)}
              />
            )}
          </RowCheckboxHolder>
        ) : null}
        {!!expansionElement && displayExpansionButton && (
          <ExpandButton loading={loading} expanded={expanded} onClick={expand} />
        )}
        {hasSubRows && expandable && (
          <SubrowButton loading={loading} expanded={expanded} onClick={expand} disabled={isRowDisabled} />
        )}
        {!!actions && <MoreActionsButton disabled={isRowDisabled} data={value} loading={loading} />}
        {columns.map((col, columnIndex) => {
          const sorted = sortDirection !== NO_DIRECTION && sortColumn === col.key;
          const width = columnSizes[columnIndex] ? columnSizes[columnIndex] : col.width;
          const isFixed = col.fixed;
          let fixedStart;
          if (isFixed) {
            fixedStart = columnSizes.slice(0, columnIndex).reduce((acc, curr) => acc + curr, 0);
          }
          return (
            <BodyCell
              height={rowHeight}
              width={width}
              // eslint-disable-next-line
              key={`body-cell-${columnIndex}-${String(col.key)}`}
              id={`body-cell-${columnIndex}-${String(col.key)}`}
              $sorted={sorted}
              $styles={StylesOverride}
              className={clsx("d-flex align-items-center bcr-table-body-cell", col.cellClass)}
              $isFixed={isFixed}
              $fixedStart={fixedStart}
            >
              <DataItem
                loading={loading}
                col={col}
                columnIndex={columnIndex}
                value={value}
                expand={expand}
                index={index}
              />
            </BodyCell>
          );
        })}
      </RowDiv>
      {shouldShowSubRows &&
        value?.subRows &&
        value?.subRows.map((subValue: any, subRowIndex: number) => {
          const override = subRowStylesGetter(index, value, subRowIndex, subValue) || rowStyle;
          return (
            <RowDiv
              height={rowHeight}
              $styles={{ ...StylesOverride, ...override }}
              // eslint-disable-next-line
              key={`row-${index}-subrow-${subRowIndex}`}
            >
              {expandable ? <SubRowButtonHolder /> : null}
              {showCheckbox ? <SubRowButtonHolder /> : null}
              {columns.map((col, columnIndex) => {
                const sorted = sortDirection !== NO_DIRECTION && sortColumn === col.name;
                const width = columnSizes[columnIndex] ? columnSizes[columnIndex] : col.width;
                return (
                  <BodyCell
                    height={rowHeight}
                    width={width}
                    // eslint-disable-next-line
                    key={`body-cell-${subRowIndex}-subrow-${columnIndex}-${String(col.key)}`}
                    $sorted={sorted}
                    $styles={StylesOverride}
                    className={clsx("d-flex align-items-center bcr-table-body-cell", col.cellClass)}
                  >
                    <DataItem
                      loading={loading}
                      col={col}
                      columnIndex={columnIndex}
                      value={subValue}
                      expand={expand}
                      index={index}
                      isSubrow={true}
                    />
                  </BodyCell>
                );
              })}
            </RowDiv>
          );
        })}
      {expanded && !!expansionElement && !!ExpansionElement && (
        <RowExpansionHolder id={`${rowPrefix}-${index}`} $styles={StylesOverride}>
          <ExpansionElement value={value} styles={StylesOverride}>
            expansion
          </ExpansionElement>
        </RowExpansionHolder>
      )}
    </div>
  );
};

const MemoizedRow = typedMemo(Row, rowPropsCompare);

export default MemoizedRow;
