import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { VariableSizeList as List, ListChildComponentProps } from "react-window";
import styled from "styled-components";
import { Typography } from "../../layout";
import { DataTableDataType } from "../../types";
import { getElement, getElementHeight, renderComponent } from "../../utils";
import { Loader } from "../loading";
import { useDataTable } from "../table/datatable/DataTableContext";

type MobileAccordionStyleProps = {
  height?: number;
  noScroll?: boolean;
};

const Holder = styled.div`
  position: relative;

  *:focus {
    outline: none;
  }
`;

const SearchResultsHolder = styled.div<MobileAccordionStyleProps>`
  overflow: visible;
  height: ${(props) => (!!props.noScroll ? "100%" : `${props.height}px`)};
`;

type OpenAccordionsType = { [index: number]: boolean };

const rowHeight = 100;

const createAccordionKey = (index: number) => `mobile-accordion-${index}`;

const getChildElementHeightById = (id: string): number => {
  const el = getElement(id);
  if (el?.children.length) {
    return getElementHeight(el.children[0] as HTMLElement);
  }
  return rowHeight;
};

const MobileAccordions = <MobileAccordingDataType extends DataTableDataType>() => {
  const {
    type,
    heights: { bodyHeight, noScroll },
    data,
    accordion: { accordion },
    scroll: { scrollToIndex },
    pagination: { setOnPaginationChanged },
    noDataMessage = "",
    loading,
  } = useDataTable<MobileAccordingDataType>();
  const isVirtualized = useMemo(() => type === "virtualized", [type]);
  const list = useRef<List>();
  const [openAccordions, setOpenAccordions] = useState<OpenAccordionsType>({});

  const recompute = useCallback(
    (index?: number) => {
      setTimeout(() => {
        if (index !== undefined) {
          list.current?.resetAfterIndex(index);
        }
      }, 10);
    },
    [list],
  );

  const accordionChanged = (index: number, newState: boolean) => {
    setOpenAccordions((existing) => {
      existing[index] = newState;
      return existing;
    });
    recompute(index);
  };

  const getRowHeight = (index: number) => getChildElementHeightById(createAccordionKey(index)) ?? rowHeight;

  const RowRenderer: React.FC<React.PropsWithChildren<ListChildComponentProps>> = useCallback(
    ({ index, style }) => {
      const accordionKey = createAccordionKey(index);
      return (
        <div className="pb-2" key={index} style={style} id={accordionKey}>
          {renderComponent(accordion, {
            data: data[index],
            index,
            accordionKey,
            startOpen: openAccordions[index],
            onClick: (newState: boolean) => accordionChanged(index, newState),
            animation: false,
          })}
        </div>
      );
    },
    [data, openAccordions],
  );

  const paginationChanged = useCallback(() => {
    if (!!list?.current?.scrollToItem) {
      list.current?.scrollToItem(0, "start");
    }
  }, [list]);

  useEffect(() => {
    recompute(0);
  }, [data, isVirtualized]);

  useEffect(() => {
    setOnPaginationChanged(paginationChanged);
  }, [paginationChanged]);

  return (
    <Holder>
      {loading ? (
        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
          <Loader size="50px" type="spinner" />
        </div>
      ) : !data?.length && !!noDataMessage?.length ? (
        <div className="d-flex justify-content-center align-items-center py-3">
          <Typography variant="div" className="text-center">
            {noDataMessage}
          </Typography>
        </div>
      ) : (
        <SearchResultsHolder noScroll={noScroll} height={bodyHeight}>
          {isVirtualized ? (
            <>
              {!!data?.length && (
                <AutoSizer>
                  {({ height, width }) => {
                    return (
                      <List
                        //@ts-ignore
                        ref={list}
                        width={width}
                        height={height}
                        itemCount={data.length}
                        itemSize={getRowHeight}
                        estimatedItemSize={rowHeight}
                      >
                        {/*@ts-ignore*/}
                        {RowRenderer}
                      </List>
                    );
                  }}
                </AutoSizer>
              )}
            </>
          ) : (
            <>
              {data.map((d: MobileAccordingDataType, index: number) => (
                <div className="mb-2" key={d.id || index}>
                  {renderComponent(accordion, {
                    data: d,
                    index,
                    accordionKey: `mobile-accordion-${index}`,
                    startOpen: openAccordions[index],
                    onClick: (newState: boolean) => accordionChanged(index, newState),
                    animation: false,
                  })}
                </div>
              ))}
            </>
          )}
        </SearchResultsHolder>
      )}
    </Holder>
  );
};

export default MobileAccordions;
