import styled from "styled-components";
import { DatatableThemeType } from "../../../types";

interface DatatableStylesType {
  $styles: DatatableThemeType;
}

//Expand Button
export const ExpansionButtonHolder = styled.div`
  min-width: 30px;
  &:hover {
    cursor: pointer;
  }
`;

//Subrow Button
export const SubRowButtonHolder = styled.div.attrs(() => ({}))`
  min-width: 31px;
  &:hover {
    cursor: pointer;
  }
`;

export const ActionsHeader = styled.div.attrs(() => ({}))`
  min-width: 31px;
`;

// More actions button
export const MoreActionsButtonStyles = styled.div`
  min-width: 31px;
  &:hover {
    cursor: pointer;
  }
`;

// Styled action for the more actions popover
export const MoreActionsPopoverAction = styled.div<DatatableStylesType>`
  display: flex;
  color: ${(props) => props.$styles.moreActions.popoverAction.color};
  font-size: ${(props) => props.$styles.moreActions.popoverAction.fontSize};
  &:hover {
    background-color: ${(props) => props.$styles.moreActions.popoverAction.hoverBackgroundColor};
    cursor: pointer;
  }
  padding: ${(props) => props.$styles.moreActions.popoverAction.padding};
`;

//Header Cell
interface HeaderCellProps {
  $styles: DatatableThemeType;
  $elemWidth: number;
  $elemHeight: number;
  $sortable: boolean;
  $background?: string;
  $isFixed?: boolean;
  $fixedStart?: number;
}

interface HeaderTextProps {
  $addRow: boolean;
}

export const HeaderCellDiv = styled.div<HeaderCellProps>`
  &&& {
    height: ${(props) => props.$elemHeight}px;
    width: ${(props) => props.$elemWidth}px;
    font-weight: ${(props) => props.$styles.header.fontWeight};
    font-size: 0.75rem;
    color: ${(props) => props.$styles.header.color};
    background: ${(props) => (!!props.$background?.length ? props.$background : props.$styles.header.background)};
    ${(props) => props.$sortable && "cursor: pointer;"}
    position: ${(props) => (props.$isFixed ? "sticky" : "relative")};
    left: ${(props) => (props.$isFixed ? `${props.$fixedStart ?? 0}px` : "auto")};
    z-index: ${(props) => (props.$isFixed ? 1050 : 1040)};
  }
`;

export const HeaderText = styled.div<HeaderTextProps>`
  width: 100%;
  display: flex;
  align-items: end;
  ${(props) => props.$addRow && "display: flex"};
  ${(props) => props.$addRow && "justify-content: space-between"};
`;

export const HeaderAddRowText = styled.div<DatatableStylesType>`
  color: ${(props) => props.$styles.moreActions.addRowText.color};
  cursor: pointer;
`;

//Header
interface HeaderStylesProps {
  height: number;
  $minWidth: number;
  $styles: DatatableThemeType;
}

export const HeaderDiv = styled.div.attrs(() => ({
  className: "d-flex align-items-end bcr-table-header",
}))<HeaderStylesProps>`
  height: ${(props) => props.height}px;
  width: 100%;
  min-width: ${(props) => props.$minWidth}px;
  border-bottom: 1px solid ${(props) => props.$styles.header.borderBottomColor};
  background: ${(props) => props.$styles.header.background};
  position: sticky;
  & > div:first-child {
    padding-left: 0.5rem;
  }
`;

export const HeaderCheckboxHolder = styled.div.attrs(() => ({
  className: "bcr-table-header-cell",
}))`
  height: 100%;
  display: flex;
  align-items: end;
  margin-bottom: 0.5rem;
`;

//Row
interface RowStylesProps {
  height?: number;
  width?: number;
  $expanded?: boolean;
  $sorted?: boolean;
  $selected?: boolean;
  disabled?: boolean;
  $isClickable?: boolean;
  $styles: DatatableThemeType;
  $isFixed?: boolean;
  $fixedStart?: number;
}

export const RowDiv = styled.div.attrs(() => ({
  className: "d-flex align-items-center bcr-table-row",
}))<RowStylesProps>`
  height: ${(props) => props.height}px;
  background: ${(props) =>
    props.disabled
      ? props.$styles.row.disabledBackground
      : props.$selected
      ? props.$styles.row.selectedBackground
      : props.$styles.row.background};
  color: ${(props) => props.$styles.row.color};
  border: ${(props) => (props.$styles.row.fullBorder ? props.$styles.row.fullBorder : null)};
  border-bottom: ${(props) => (props.$styles.row.fullBorder ? null : "1px solid " + props.$styles.row.separatorColor)};
  ${(props) => props.$expanded && "box-shadow: 0px 20px 15px -15px silver"};
  position: relative;
  z-index: 1050;
  &:hover {
    ${(props) =>
      props.$isClickable
        ? props.onClick && !props.disabled && "cursor: pointer"
        : undefined === props.$isClickable
        ? ""
        : "cursor: not-allowed"};
    ${(props) => props.onClick && !props.disabled && `background: ${props.$styles.row.selectedBackground}`};
  }
  & > div:first-child {
    padding-left: 0.5rem;
  }
`;

export const BodyCell = styled.div<RowStylesProps>`
  background: inherit !important;
  border-bottom: inherit;
  border-top: inherit;
  min-width: ${(props) => props.width}px;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  ${(props) => (!!props.$sorted ? `background: ${props.$styles.column.sortedBackground}` : "")};
  position: ${(props) => (props.$isFixed ? "sticky" : "relative")};
  left: ${(props) => (props.$isFixed ? `${props.$fixedStart ?? 0}px` : "auto")};
  z-index: ${(props) => (props.$isFixed ? 1050 : 1040)};
`;

export const RowExpansionHolder = styled.div<RowStylesProps>`
  border-bottom: 1px solid ${(props) => props.$styles.row.separatorColor};
  background: ${(props) => props.$styles.row.expandedBackground};
  position: relative;
  z-index: 1050;
`;

export const RowCheckboxHolder = styled.div.attrs(() => ({
  className: "bcr-table-body-cell",
}))`
  min-width: 1.0625rem;
  height: 50%;
`;

//Datatable
type DatatableStylesProps = {
  height?: number;
  noScroll?: boolean;
  styles: DatatableThemeType;
  minWidth?: number;
};

export const TableStyled = styled.div<DatatableStylesProps>`
  *:focus {
    outline: none;
  }

  position: relative;
  border: ${(props) => props.styles.datatable.border};
  overflow-x: auto;
  width: 100%;

  &::-webkit-scrollbar {
    width: 1rem;
  }
  scrollbar-width: thin;

  &::-webkit-scrollbar-track {
    background: ${(props) => props.styles.scrollBar.background};
  }
  scrollbar-background: ${(props) => props.styles.scrollBar.background};

  &::-webkit-scrollbar-thumb {
    border: 3px solid ${(props) => props.styles.scrollBar.element.borderColor};
    border-radius: 100px;
    background-color: ${(props) => props.styles.scrollBar.element.color};
    background-clip: content-box;
  }
  scrollbar-color: ${(props) => props.styles.scrollBar.element.borderColor};
`;

export const BodyStyled = styled.div.attrs(() => ({
  className: "bcr-table-body",
}))<DatatableStylesProps>`
  overflow: visible;
  height: ${({ noScroll, height }) => (!!noScroll ? "100%" : `${height}px`)};
  min-width: ${({ minWidth }) => `${minWidth}px`};

  .ReactVirtualized__Grid {
    &::-webkit-scrollbar {
      width: 1rem;
    }
    scrollbar-width: thin;

    &::-webkit-scrollbar-track {
      background: ${(props) => props.styles.scrollBar.background};
    }
    scrollbar-background: ${(props) => props.styles.scrollBar.background};

    &::-webkit-scrollbar-thumb {
      border: 3px solid ${(props) => props.styles.scrollBar.element.borderColor};
      border-radius: 100px;
      background-color: ${(props) => props.styles.scrollBar.element.color};
      background-clip: content-box;
    }
    scrollbar-color: ${(props) => props.styles.scrollBar.element.borderColor};
  }
`;

export const LoadingHolder = styled.div`
  height: 100%;
  width: 100%;
  padding: 7px;
`;

type ScrollDownContainerProps = {
  hasHorizontalScroll: boolean;
};

export const ScrollDownContainer = styled.div<ScrollDownContainerProps>`
  position: sticky;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  width: 100%;
  padding: 0.25em 0;
  margin-top: ${(props) => (props.hasHorizontalScroll ? `-42px` : `-2rem`)};
  z-index: 1054;
`;
