import React, { useCallback, useEffect, useRef } from "react";

import AutoSizer from "react-virtualized-auto-sizer";
import { VariableSizeList as List, ListChildComponentProps, ListOnScrollProps } from "react-window";
import { DataTableDataType } from "../../../../types";
import { getElementHeightById } from "../../../../utils";
import { useDataTable } from "../DataTableContext";
import DataTableInfiniteLoader from "../common/DataTableInfiniteLoader";
import Row from "../common/Row";

const DataTableVirtualized = <T extends DataTableDataType>() => {
  const {
    data,
    widths: { tableWidth },
    heights: { loadingHeight, rowHeight },
    columnSizes,
    loading,
    rowPrefix,
    pagination: { setOnPaginationChanged },
    scroll: { scrollToIndex, onTableScroll },
    infiniteLoader,
  } = useDataTable<T>();

  const list = useRef<List>();
  const innerList = useRef<HTMLDivElement>();

  const paginationChanged = useCallback(() => {
    if (!!list?.current?.scrollTo) {
      list.current!.scrollToItem(0, "start");
    }
  }, [list]);

  const recompute = useCallback(
    (index?: number) => {
      setTimeout(() => {
        if (index !== undefined) {
          list.current?.resetAfterIndex(index);
        }
      }, 10);
    },
    [list],
  );

  const createRowId = (index: number) => `${rowPrefix}-row-${index}`;

  const getRowHeight = (index: number) => getElementHeightById(createRowId(index)) ?? rowHeight;

  const RowRenderer = ({ index, style }: ListChildComponentProps) => {
    const value: T = data[index];
    const id = createRowId(index);
    return (
      <Row
        id={id}
        key={`${id}-${JSON.stringify(columnSizes)}`}
        style={style}
        index={index}
        value={value}
        recompute={recompute}
        loading={loading || index >= data.length}
      />
    );
  };

  const onScroll = (props: ListOnScrollProps) => {
    if (!!innerList?.current?.parentElement) {
      const { scrollHeight, clientHeight } = innerList.current.parentElement;
      if (scrollHeight !== undefined && clientHeight !== undefined) {
        onTableScroll({
          scrollTop: props.scrollOffset,
          scrollHeight,
          clientHeight,
        });
      }
    }
  };

  useEffect(() => {
    setOnPaginationChanged(paginationChanged);
  }, [paginationChanged]);

  useEffect(() => {
    recompute(0);
  }, [data]);

  useEffect(() => {
    if (scrollToIndex !== undefined) {
      if (!!list?.current?.scrollTo) {
        list.current!.scrollToItem(scrollToIndex, "center");
      }
    }
  }, [scrollToIndex]);

  return (
    <AutoSizer>
      {({ height, width }) => {
        const finalTableWidth = Math.max(width, tableWidth);
        const finalHeight = loading ? loadingHeight : height;
        const loadingRows = Math.floor(finalHeight / rowHeight);
        return infiniteLoader ? (
          <DataTableInfiniteLoader data={data} {...infiniteLoader}>
            {({ onItemsRendered, ref }) => (
              <List
                ref={(_list) => {
                  list.current = _list || undefined;
                  ref(_list);
                }}
                width={finalTableWidth}
                height={finalHeight}
                itemCount={infiniteLoader.rowCount}
                itemSize={getRowHeight}
                onScroll={onScroll}
                onItemsRendered={onItemsRendered}
              >
                {/*@ts-ignore*/}
                {RowRenderer}
              </List>
            )}
          </DataTableInfiniteLoader>
        ) : (
          <List
            ref={(_list) => {
              list.current = _list || undefined;
            }}
            innerRef={innerList}
            width={finalTableWidth}
            height={finalHeight}
            itemCount={loading ? loadingRows : data.length}
            estimatedItemSize={rowHeight}
            itemSize={getRowHeight}
            onScroll={onScroll}
          >
            {/*@ts-ignore*/}
            {RowRenderer}
          </List>
        );
      }}
    </AutoSizer>
  );
};

export default DataTableVirtualized;
